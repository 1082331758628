import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import Logo from '../../atoms/Logo';

const LogoBox = styled(Box)({
  maxWidth: '100px',
  width: '100%',
  padding: '0 0 16px 0'
});

const TextBox = styled(Box)({
  padding: '48px 24px'
});

const SuccessMessage = () => {
  return (
    <>
      <TextBox>
        <Typography align="center">
          Thank you for your support and service by submitting a Cyber Incident
          Report.
          <br />
          <br />
        </Typography>
      </TextBox>
      <LogoBox>
        <Logo />
      </LogoBox>
    </>
  );
};

export default SuccessMessage;
