/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Tab, Tabs, Typography, Link } from '@mui/material';
import LoginForm from '../../molecules/LoginForm';
import RegisterForm from '../../molecules/RegisterForm';
import RegistrationConfirmation from '../../molecules/RegistrationConfirmation';
import Logo from '../../atoms/Logo';

const PanelBox = styled(Box)({
  display: 'flex',
  flexFlow: 'column',
  width: 'auto'
});

const Panel = styled('div')({
  width: '100%',
  maxWidth: '326px',
  padding: '24px 12px'
});

const TabsContainer = styled((props) => <Tabs {...props} />)(({ theme }) => ({
  width: '100%',
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    borderBottom: 'none'
  }
}));

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'uppercase',
    borderBottom: '1px solid #ffffffb3',
    fontWeight: theme.typography.fontWeightRegular,
    '&.Mui-selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightBold
    },
    '&.Mui-focusVisible': {
      backgroundColor: theme.palette.primary.main
    }
  })
);

const RegisterLink = styled((props) => <Link {...props} />)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.text.primary
}));

const LogoBox = styled(Box)({
  maxWidth: '100px',
  width: '100%',
  margin: '0 auto',
  padding: '0 0 8px 0'
});

const GrayText = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey[400],
  padding: '0px 16px',
  justifyContent: 'center'
}));

const TabPanel = (props) => {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props;

  return (
    <Panel
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <PanelBox>{children}</PanelBox>}
    </Panel>
  );
};

const TabsContainerPadded = styled((props) => <TabsContainer {...props} />)(
  () => ({
    maxWidth: '326px',
    paddingLeft: '12px',
    paddingRight: '12px'
  })
);

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const LoginPageForm = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [registerConfirmation, setRegisterConfirmation] = useState(false);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      <Box pb={2} px={2} width="100%">
        <LogoBox display="flex" justifyContent="center">
          <Logo />
        </LogoBox>
        <GrayText variant="body2" align="center" data-testid="lpfd-paragraph">
          {!registerConfirmation &&
            (tabIndex === 1 ? (
              <>
                Submit your contact information to request a PIN Code in order
                access the site. If you already have a PIN Code, click the
                &nbsp;
                <RegisterLink
                  component="button"
                  underline="hover"
                  onClick={() => setTabIndex(0)}
                >
                  Login
                </RegisterLink>
                &nbsp; tab for access.
              </>
            ) : (
              tabIndex === 0 && (
                <>
                  Enter your email and PIN Code below to access the site. If you
                  do not have a PIN Code, click &nbsp;
                  <RegisterLink
                    component="button"
                    underline="hover"
                    onClick={() => setTabIndex(1)}
                  >
                    Register
                  </RegisterLink>
                  &nbsp; to request one.
                </>
              )
            ))}
        </GrayText>
      </Box>
      <TabsContainerPadded
        value={tabIndex}
        onChange={handleChange}
        aria-label="basic tabs example"
        variant="fullWidth"
        centered
      >
        <AntTab label="Login" {...a11yProps(0)} />
        <AntTab label="Register" {...a11yProps(1)} />
      </TabsContainerPadded>
      <TabPanel value={tabIndex} index={0}>
        <LoginForm />
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        {registerConfirmation ? (
          <RegistrationConfirmation />
        ) : (
          <RegisterForm setRegisterConfirmation={setRegisterConfirmation} />
        )}
      </TabPanel>
    </>
  );
};

export default LoginPageForm;
