import React from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Cookies from 'js-cookie';
import { Context as authContext } from './context/auth';
import { SPP_USER_VERIFY } from './utils/queries';

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ children, redirectTo }) => {
  const { state } = React.useContext(authContext);
  const authToken = Cookies.get('refresh_token');

  const {
    data: authResult,
    error: authError,
    loading: authLoading
  } = useQuery(SPP_USER_VERIFY);

  if (!(state.token || authToken)) return <Navigate to={redirectTo} />;

  if (!authLoading) {
    if (!authError) {
      if (authResult) {
        return authResult.spp_user_verify ? (
          children
        ) : (
          <Navigate to={redirectTo} />
        );
      }
    }
  }
  return false;
};

export default PrivateRoute;
