import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import LoginPageForm from '../../organisms/LoginPageForm';

const WrapperContainer = styled(Container)({
  backgroundColor: '#212121'
});

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '36px 0',
  width: 'auto',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
    width: 'auto'
  }
}));

const LoginPage = () => {
  return (
    <WrapperContainer component="div" maxWidth="false" disableGutters>
      <InnerContainer>
        <LoginPageForm />
      </InnerContainer>
    </WrapperContainer>
  );
};

export default LoginPage;
