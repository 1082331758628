import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import { SPP_USER_ACCEPT, SPP_USER_DENY } from './mutations';

const WrapperContainer = styled(Container)({
  backgroundColor: '#212121'
});

const InnerContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '36px 0',
  width: 'auto',
  position: 'relative',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
    width: 'auto'
  }
}));

const NewUserPage = () => {
  const [message, setMessage] = useState('');
  const { action, token } = useParams();

  const mutation = action === 'accept' ? SPP_USER_ACCEPT : SPP_USER_DENY;
  const resultName = action === 'accept' ? 'spp_user_accept' : 'spp_user_deny';
  const [newUserAction] = useMutation(mutation);

  const performAction = async () => {
    await newUserAction({
      variables: { token }
    })
      .then(({ data }) => {
        setMessage(data[resultName]);
        return true;
      })
      .catch((e) => {
        if (e.graphQLErrors[0]) setMessage(e.graphQLErrors[0].message);
        return false;
      });
    return false;
  };

  useEffect(() => {
    // check incoming parameters
    if (['accept', 'deny'].includes(action) && token.length === 64) {
      performAction();
    } else {
      setMessage('Invalid Call');
    }
  }, []);

  return (
    <WrapperContainer component="div" maxWidth="false" disableGutters>
      <InnerContainer>
        <h2>{message}</h2>
      </InnerContainer>
    </WrapperContainer>
  );
};

export default NewUserPage;
