/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import ReportForm from '../../molecules/ReportForm';

const HeaderText = styled((props) => <Typography {...props} />)(
  ({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold
  })
);

const BorderBox = styled((props) => <Box {...props} />)({
  padding: '36px 24px',
  width: '100%',
  position: 'relative'
});

const ReportPageForm = () => {
  return (
    <>
      <Box pb={2} px={2}>
        <HeaderText variant="h5" align="center" color="primary">
          Create a New Cyber Incident Report
        </HeaderText>
      </Box>
      <BorderBox>
        <ReportForm />
      </BorderBox>
    </>
  );
};

export default ReportPageForm;
