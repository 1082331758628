import React, { forwardRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TextField, Button, InputAdornment } from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { SPP_PIN_RESET } from './mutations';

const ResendButton = styled(Button)({
  marginTop: '16px'
});

// eslint-disable-next-line react/jsx-props-no-spreading
const BackButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  marginTop: theme.spacing(5)
}));

const BackLink = forwardRef((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RouterLink ref={ref} to="/login" {...props} />
));

const validationSchema = yup.object({
  email: yup
    .string('Enter your email')
    .max(120, 'Must be under 120 characters')
    .trim()
    .email('Enter a valid email')
    .required('Email is required')
});

const ResetPinForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [portalPinReset] = useMutation(SPP_PIN_RESET);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await portalPinReset({
        variables: {
          email: values.email.toLowerCase()
        }
      })
        .then(() => {
          // show email message
          formik.setSubmitting(false);
          setLoading(false);
          setCompleted(true);
        })
        // err
        .catch((e) => {
          formik.setSubmitting(false);
          if (e.graphQLErrors[0]) setError(e.graphQLErrors[0].message);
          setLoading(false);
          setCompleted(true);
        });
    }
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {completed && (
          <Alert severity="success" variant="filled" sx={{ marginTop: '12px' }}>
            If you are found in our system, you will be sent a new PIN Code via
            email.
          </Alert>
        )}
        <TextField
          required
          label="Email"
          name="email"
          value={formik.values.email}
          margin="normal"
          fullWidth
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MailOutline
                  sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                  fontSize="small"
                />
              </InputAdornment>
            )
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{ 'data-testid': 'reset-pin-email' }}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <ResendButton fullWidth variant="contained" type="submit">
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Send New PIN Code'
          )}
        </ResendButton>
        {error && (
          <Alert severity="error" variant="filled" sx={{ marginTop: '12px' }}>
            {error}
          </Alert>
        )}
      </form>
      <BackButton component={BackLink} variant="outlined">
        Back to Login
      </BackButton>
    </>
  );
};

export default ResetPinForm;
