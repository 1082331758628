import { gql } from '@apollo/client/core';

const SPP_PIN_RESET = gql`
  mutation sppPinReset($email: String!) {
    spp_pin_reset(email: $email)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_PIN_RESET };
