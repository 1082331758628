import { gql } from '@apollo/client/core';

const SPP_COUNTRIES_LIST = gql`
  query sppCountries {
    spp_countries {
      id
      name
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_COUNTRIES_LIST };
