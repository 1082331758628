import { gql } from '@apollo/client/core';

const SPP_USER_LOGIN = gql`
  mutation sppUserLogin(
    $email: String!
    $pin_code: String!
    $recaptcha: String!
  ) {
    spp_user_login(email: $email, pin_code: $pin_code, recaptcha: $recaptcha) {
      id
      country_id
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_USER_LOGIN };
