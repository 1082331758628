import { gql } from '@apollo/client/core';

const SPP_USER_ACCEPT = gql`
  mutation sppUserAccept($token: String!) {
    spp_user_accept(token: $token)
  }
`;

const SPP_USER_DENY = gql`
  mutation sppUserDeny($token: String!) {
    spp_user_deny(token: $token)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_USER_ACCEPT, SPP_USER_DENY };
