import { gql } from '@apollo/client/core';

const SPP_USER_REGISTER = gql`
  mutation sppUserRegister(
    $name: String!
    $email: String!
    $organization: String!
    $phone: String!
    $access_reason: String!
    $country: String!
    $recaptcha: String!
  ) {
    spp_user_register(
      name: $name
      email: $email
      organization: $organization
      phone: $phone
      access_reason: $access_reason
      country: $country
      recaptcha: $recaptcha
    )
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_USER_REGISTER };
