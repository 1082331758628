import React from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import ReportPageForm from '../../organisms/ReportPageForm';

const WrapperContainer = styled(Container)({
  backgroundColor: '#212121',
  colorScheme: 'dark'
});

const InnerContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 auto',
  padding: '36px 0',
  width: 'auto',
  position: 'relative',
  maxWidth: '800px'
});

const CreateReportPage = () => {
  return (
    <WrapperContainer component="div" maxWidth="false" disableGutters>
      <InnerContainer>
        <ReportPageForm />
      </InnerContainer>
    </WrapperContainer>
  );
};

export default CreateReportPage;
