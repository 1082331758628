/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import ResetPinForm from '../../molecules/ResetPinForm';
import Logo from '../../atoms/Logo';

const GrayText = styled((props) => <Typography {...props} />)(({ theme }) => ({
  color: theme.palette.grey[400],
  padding: '0px 16px'
}));

const LogoBox = styled(Box)({
  maxWidth: '100px',
  minWidth: '50px',
  width: '100%',
  margin: '0 auto',
  padding: '0 0 8px 0'
});

const FormBox = styled(Box)({
  display: 'flex',
  flexFlow: 'column',
  width: '100%',
  maxWidth: '326px',
  padding: '0px 12px 24px 12px'
});

const FormTitle = styled((props) => <Box {...props} />)(({ theme }) => ({
  borderBottomWidth: 2,
  borderBottomStyle: 'solid',
  borderBottomColor: theme.palette.primary.main,
  width: '100%',
  maxWidth: '326px',
  textTransform: 'uppercase',
  padding: '12px 16px',
  marginBottom: '24px',
  display: 'flex',
  justifyContent: 'center',
  verticalAlign: 'middle'
}));

const Title = styled((props) => <Typography {...props} />)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold
}));

const ResetPinPageForm = () => {
  return (
    <>
      <Box pb={2} px={2}>
        <LogoBox>
          <Logo />
        </LogoBox>
        <GrayText variant="body2" align="center">
          Enter your email address below to receive a new PIN Code.
        </GrayText>
      </Box>
      <FormBox>
        <FormTitle>
          <Title color="primary" variant="button">
            PIN Reset
          </Title>
        </FormTitle>
        <ResetPinForm />
      </FormBox>
    </>
  );
};

export default ResetPinPageForm;
