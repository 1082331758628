/* eslint-disable no-console */
import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import {
  ApolloClient,
  InMemoryCache,
  from,
  HttpLink,
  ApolloProvider
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { Provider as AuthProvider } from './context/auth';
import { Provider as UserProvider } from './context/user';
import PrivateRoute from './PrivateRoute';
import LoginPage from './components/pages/LoginPage/LoginPage';
import CreateReportPage from './components/pages/CreateReportPage/CreateReportPage';
import ResetPinPage from './components/pages/ResetPinPage';
import SuccessPage from './components/pages/SuccessPage';
import NewUserPage from './components/pages/NewUserPage';
import theme from './assets/theme/theme';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  console.log('errorLink Firing');
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) {
    console.log(`[Network error]: ${networkError.statusCode}`);
    console.log(`[Network error]: ${networkError.result}`);
    console.log(`[Network error]: ${networkError.message}`);
    if (
      networkError.statusCode === 401 ||
      networkError.message === 'jwt expired' ||
      networkError.message === 'Token not found'
    ) {
      // send em back to login
      window.location.href = '/login';
    }
  }
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_ACCESS_POINT}/graphql`,
  credentials: 'include',
  headers: { 'Application-Context': 'spp' }
});

const link = from([errorLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache()
});

const App = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloProvider client={client}>
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<LoginPage />} />
              <Route
                path="/form"
                element={
                  <PrivateRoute redirectTo="/login">
                    <CreateReportPage />
                  </PrivateRoute>
                }
              />
              <Route path="/success" element={<SuccessPage />} />
              <Route path="/reset" element={<ResetPinPage />} />
              <Route path="/newuser/:action/:token" element={<NewUserPage />} />
            </Routes>
          </ApolloProvider>
        </ThemeProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default App;
