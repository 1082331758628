/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState, useRef, lazy, Suspense } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  TextField,
  Button,
  InputAdornment,
  CircularProgress,
  FormHelperText,
  Skeleton,
  Autocomplete,
} from '@mui/material';
import { MailOutline } from '@mui/icons-material';
import Alert from '@mui/material/Alert';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { SPP_USER_REGISTER } from './mutations';
import { SPP_COUNTRIES_LIST } from './queries';
import './PhoneInputOverride.css';

const ReCAPTCHACheck = lazy(() => import('../../atoms/ReCAPTCHA'));

const validationSchema = yup.object({
  name: yup
    .string('Enter your full name')
    .max(120, 'Must be under 120 characters')
    .trim()
    .required('Full name is required'),
  organization: yup
    .string('Enter your organization')
    .max(120, 'Must be under 120 characters')
    .trim()
    .required('Organization is required'),
  email: yup
    .string('Enter your email')
    .max(120, 'Must be under 120 characters')
    .trim()
    .email('Enter a valid email')
    .required('Email is required'),
  phone: yup
    .string()
    .matches(/^[0-9]{0,15}$/, 'Invalid phone number')
    .min(7, 'Must be 7 digits or more')
    .max(15, 'Must be under 15 digits')
    .trim()
    .required('Phone number is required'),
  country: yup.object().required('Country is required'),
  accessReason: yup
    .string('Enter a reason for requesting access')
    .max(120, 'Must be under 120 characters')
    .trim()
    .required('Access Reason is required'),
  recaptcha: yup.string().required(),
});

const RegisterForm = ({ setRegisterConfirmation }) => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const recaptchaRef = useRef(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [registerUser] = useMutation(SPP_USER_REGISTER);
  const {
    error: countryError,
    loading: countryLoading,
    data: countryData,
  } = useQuery(SPP_COUNTRIES_LIST);

  const formik = useFormik({
    initialValues: {
      name: '',
      organization: '',
      email: '',
      phone: '',
      country: '',
      accessReason: '',
      recaptcha: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      await registerUser({
        variables: {
          name: values.name,
          email: values.email.toLowerCase(),
          organization: values.organization,
          phone: values.phone,
          access_reason: values.accessReason,
          country: values.country?.id,
          recaptcha: values.recaptcha,
        },
      })
        .then(({ data }) => {
          formik.setSubmitting(false);
          if (data.spp_user_register) {
            setRegisterConfirmation(true);
          }
          setLoading(false);
        })
        // err
        .catch((e) => {
          formik.setSubmitting(false);
          if (e.graphQLErrors[0]) setError(e.graphQLErrors[0].message);
          recaptchaRef.current.reset();
          setLoading(false);
        });
    },
  });

  const countrySelect = () => {
    if (countryError) {
      return <>Error</>;
    }

    if (countryLoading) {
      return <Skeleton variant="rounded" height={45} />;
    }

    return (
      <Autocomplete
        disablePortal
        id="country"
        fullWidth
        onChange={(e, value) => formik.setFieldValue('country', value)}
        options={countryData?.spp_countries || []}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Country"
            margin="normal"
            required
            error={formik.touched.country && Boolean(formik.errors.country)}
            helperText={formik.touched.country && formik.errors.country}
          />
        )}
      />
    );
  };

  const onRecaptchaChange = (value) => {
    formik.setFieldValue('recaptcha', value);
    formik.setSubmitting(false);
    setDisableSubmit(false);
  };

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="off">
      <TextField
        required
        id="name"
        label="Full Name"
        name="name"
        value={formik.values.name}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <TextField
        required
        id="organization"
        label="Organization"
        name="organization"
        value={formik.values.organization}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={
          formik.touched.organization && Boolean(formik.errors.organization)
        }
        helperText={formik.touched.organization && formik.errors.organization}
      />
      <TextField
        required
        id="email"
        label="Email"
        name="email"
        value={formik.values.email}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutline
                sx={{ color: 'action.active', mr: 1, my: 0.5 }}
                fontSize="small"
              />
            </InputAdornment>
          ),
        }}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <PhoneInput
        name="phone"
        id="phone"
        specialLabel="Phone*"
        inputProps={{
          name: 'phone',
          required: true,
        }}
        placeholder="+246 375 1234"
        country="1" // default country
        value={formik.values.phone}
        onBlur={formik.handleBlur}
        onChange={(phone) => formik.setFieldValue('phone', phone)}
      />
      <FormHelperText
        error={formik.touched.phone && Boolean(formik.errors.phone)}
      >
        {formik.touched.phone && formik.errors.phone}
      </FormHelperText>
      {countrySelect()}
      <TextField
        required
        id="accessReason"
        label="Access Reason"
        name="accessReason"
        value={formik.values.accessReason}
        margin="normal"
        fullWidth
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        error={
          formik.touched.accessReason && Boolean(formik.errors.accessReason)
        }
        helperText={formik.touched.accessReason && formik.errors.accessReason}
      />
      <Suspense fallback={<Skeleton width={300} height={74} />}>
        <ReCAPTCHACheck ref={recaptchaRef} onChange={onRecaptchaChange} />
      </Suspense>
      <Button
        fullWidth
        variant="contained"
        type="submit"
        disabled={disableSubmit}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : 'Sign Up'}
      </Button>
      {error && (
        <Alert severity="error" variant="filled">
          {error}
        </Alert>
      )}
    </form>
  );
};

export default RegisterForm;
