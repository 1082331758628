import { gql } from '@apollo/client/core';

const SPP_USER_VERIFY = gql`
  query sppUserVerify {
    spp_user_verify
  }
`;

// eslint-disable-next-line import/prefer-default-export
export { SPP_USER_VERIFY };
