/* eslint-disable no-shadow */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  lazy,
  Suspense,
  useContext,
  useRef,
  forwardRef,
  useMemo,
  Fragment
} from 'react';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik, FormikProvider, FieldArray, Field } from 'formik';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormControlLabel,
  Collapse,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  NativeSelect,
  Select,
  InputLabel,
  CircularProgress,
  Alert,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  InputAdornment,
  Popover
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import {
  Delete,
  Add,
  ExpandLess,
  ExpandMore,
  ArrowDropDown,
  ArrowDropUp
} from '@mui/icons-material';
import {
  severityRatings,
  sectors,
  observablesTypes,
  relTos,
  threatVectors
} from './StaticValues';
import { Context as authContext } from '../../../context/auth';
import { Context as userContext } from '../../../context/user';
import { getCurrentDateForInput } from '../../../utils/datetime';

const FileUpload = lazy(() => import('../FileUpload'));

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const AssessmentLabel = styled((props) => <FormLabel {...props} />)({
  fontSize: '0.75rem',
  marginTop: '8px'
});

const ArrayFieldFullWidth = styled(
  forwardRef((props, ref) => <Field innerRef={ref} {...props} />)
)({
  width: '100%'
});

const ArrayText = styled((props) => <Typography {...props} />)({
  padding: '0.5em 1.5em 0.25em 0',
  fontSize: '1em',
  width: '100%',
  display: 'inline-block',
  position: 'relative',
  zIndex: '1',
  lineHeight: '1.8em',
  borderBottom: '1px rgba(255, 255, 255, 0.7) solid'
});

const DeleteIcon = styled(
  forwardRef((props, ref) => <Delete ref={ref} {...props} />)
)({
  marginLeft: '-1em',
  width: '1em',
  position: 'relative',
  zIndex: '2',
  '&:hover': {
    cursor: 'pointer'
  }
});

const BoldText = styled((props) => <Typography {...props} />)({
  fontWeight: 'bold'
});

const InfoText = styled((props) => <Typography {...props} />)({
  fontSize: '0.75rem',
  color: '#ffffffb3'
});

const InputWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center'
});

const ObservablesCell = styled((props) => <TableCell {...props} />)({
  padding: '.25em',
  //   borderBottom: 'none',
  '&:last-child': {
    paddingRight: 0
  }
});

const CustomInput = styled(
  forwardRef((props, ref) => <Input ref={ref} {...props} />)
)({
  marginTop: '10px'
});

const UploadButton = styled((props) => <Button {...props} />)({
  margin: '16px 0 24px'
});

const UppercaseValues = styled((props) => <MenuItem {...props} />)({
  textTransform: 'uppercase'
});

const UppercaseSelect = styled((props) => <Select {...props} />)({
  textTransform: 'uppercase'
});

const SectorSubSectors = styled((props) => <MenuItem {...props} />)({
  fontWeight: 'bold',
  textDecoration: 'underline',
  textTransform: 'uppercase'
});

const SubSectors = styled((props) => <MenuItem {...props} />)({
  backgroundColor: '#333',
  paddingLeft: '3em',
  textTransform: 'uppercase'
});

const CheckBoxFormLabel = styled((props) => <FormControlLabel {...props} />)({
  alignItems: 'normal',
  marginRight: 0
});

const CheckBox = styled((props) => <Checkbox {...props} />)({
  alignItems: 'flex-start'
});

const ThreatVectorList = styled((props) => <List {...props} />)({
  backgroundColor: '#333',
  width: 'auto',
  margin: '0px',
  padding: '0px'
});

const SubVectorList = styled((props) => <List {...props} />)({
  margin: '0px',
  padding: '0px'
});

const SubVector = styled((props) => <ListItem {...props} />)({
  backgroundColor: '#333',
  paddingLeft: '3em',
  '&.Mui-selected': {
    backgroundColor: '#444'
  }
});

const AddIcon = styled((props) => <Add {...props} />)({
  marginLeft: '-1em',
  width: '1em',
  position: 'relative',
  zIndex: '2',
  '&:hover': {
    cursor: 'pointer'
  }
});
const HighContrastTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#444',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: 'bold',
    padding: '1em 1.5em'
  }
}));

// For validation: check if any top level values in sectors list are represented
// in the values of the sectors dropdown.
// This prevents a false positive if the user left subsectors present and
// closed the parent sector.
const topLevelSectorsTest = (values) =>
  sectors.some((sector) => values.includes(sector.value));

const validationSchema = yup.object({
  sectors: yup
    .array()
    .test('has-top-level-sector', `Please Select a Sector`, (value) =>
      topLevelSectorsTest(value)
    )
    .required('Please Select a Sector')
    .min(1, 'Please Select a Sector'),
  severity: yup.string().required('Please Select a Severity Rating'),
  sourceCode: yup.string().required('Please Select a Source Code').nullable(),
  incidentStart: yup
    .date()
    .required('Please Select an Incident Start Date')
    .max(new Date(), 'Incident can not start in the future'),
  incidentEnd: yup
    .date()
    .min(
      yup.ref('incidentStart'),
      'Incident End Date must be after Incident Start Date'
    )
    .max(new Date(), 'Incident can not start in the future'),
  threatVector: yup.string().trim().required('Please Select a Threat Vector'),
  attributionAssessment: yup
    .string()
    .trim()
    .min(1, 'Please Enter a Valid Attribution Assessment'),
  attributionAssessments: yup
    .array()
    .min(1, 'Please Add an Attribution Assessment')
    .required('Please Add an Attribution Assessment'),
  sources: yup.array(),
  sourcesInput: yup.string().trim().min(1, 'Please Enter a Valid Source'),
  observables: yup.array(),
  summary: yup.string().trim().required('Please Enter a Summary'),
  technicalName: yup
    .string()
    .trim()
    .required('Please Enter a Technical Contact'),
  technicalEmail: yup
    .string()
    .email('Please Enter a Valid Email Address')
    .required('Please Enter a Technical Contact Email'),
  acceptTerms: yup
    .bool()
    .oneOf([true], 'Accept Information Sharing Terms & Conditions is required'),
  observablesValue: yup
    .string()
    .when('observablesType', {
      is: 'CVE',
      then: yup
        .string()
        .matches(
          /^((?<=\b)CVE-(\d+)-(\d+)(?=\b))/,
          'Please Enter a Valid CVE (e.g. CVE-YYYY-XXXX)'
        )
    })
    .when('observablesType', {
      is: 'DOMAIN',
      then: yup
        .string()
        .matches(
          /^([(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{1,256}\[?\.\]?[A-Za-z0-9_.]{1,}|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\b([\\w\\W]*)/,
          'Please Enter a Valid Domain (e.g. Domain http://www.xxxx.com, www.xxxx.net, xxx.com, or www[.]xxxxx.com)'
        )
    })
    .when('observablesType', {
      is: 'EMAIL',
      then: yup
        .string()
        .matches(
          /^[^@ ]+@[^@ ]+\.[^@ ]+$/,
          'Please Enter a Valid Email (e.g. Email xxxxx@xxxxx.com)'
        )
    })
    .when('observablesType', {
      is: 'IPV4',
      then: yup
        .string()
        .matches(
          /^[0]*?(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\[?\.\]?){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\\(\\d|[1-2]\\d|3[0-2]))?$/,
          'Please Enter a Valid IPV4 (e.g. IPV4 xxx.x.x.xxx or xxx[.]x.x.xxx)'
        )
    })
    .when('observablesType', {
      is: 'IPV6',
      then: yup
        .string()
        .matches(
          /^(([a-zA-Z0-9]{1,4}:){7,7}[a-zA-Z0-9]{1,4}|([a-zA-Z0-9]{1,4}:){1,7}:|([a-zA-Z0-9]{1,4}:){1,6}:[a-zA-Z0-9]{1,4}|([a-zA-Z0-9]{1,4}:){1,5}(:[a-zA-Z0-9]{1,4}){1,2}|([a-zA-Z0-9]{1,4}:){1,4}(:[a-zA-Z0-9]{1,4}){1,3}|([a-zA-Z0-9]{1,4}:){1,3}(:[a-zA-Z0-9]{1,4}){1,4}|([a-zA-Z0-9]{1,4}:){1,2}(:[a-zA-Z0-9]{1,4}){1,5}|[a-zA-Z0-9]{1,4}:((:[a-zA-Z0-9]{1,4}){1,6})|:((:[a-zA-Z0-9]{1,4}){1,7}|:)|fe80:(:[a-zA-Z0-9]{0,4}){0,4}%[a-zA-Z0-9]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
          'Please Enter a Valid IPV6 (e.g. IPV6 xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxx:xxxxx)'
        )
    })
    .when('observablesType', {
      is: 'MAC',
      then: yup
        .string()
        .matches(
          /^((?<=\b)([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})(?=\b))/,
          'Please Enter a Valid MAC (e.g. MAC xx:xx:xx:xx:xx:xx)'
        )
    })
    .when('observablesType', {
      is: 'SHA1',
      then: yup.string().matches(/^[a-zA-Z0-9]+$/, 'Please Enter a Valid SHA1')
    })
    .when('observablesType', {
      is: 'SHA256',
      then: yup
        .string()
        .matches(/^[a-zA-Z0-9]+$/, 'Please Enter a Valid SHA256')
    })
    .when('observablesType', {
      is: 'URL',
      then: yup
        .string()
        .matches(
          /^([(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=-]{1,256}\[?\.\]?[A-Za-z0-9_.]{1,}|(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\b([\\w\\W]*)/,
          'Please Enter a Valid URL (e.g. URL http://www.xxxx.com, www.xxxx.net, xxx.com, or www[.]xxxxx.com)'
        )
    })
});

const ReportForm = () => {
  const [openUpload, setOpenUpload] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { logoutWORefresh: logoutUser } = useContext(authContext);
  const { state: user } = useContext(userContext);
  const [threatVectorMenuOpen, setThreatVectorMenuOpen] = useState(false);
  const [threatVectorSelected, setThreatVectorSelected] = useState({
    name: '',
    subVector: { name: '' }
  });
  const threatVectorElement = useRef();
  const observableValueInputRef = useRef();

  const handleOpenUpload = () => {
    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  // used to format users registration country
  const sourceCountry = useMemo(() => {
    if (user) {
      return relTos.find((country) => country.key === user.country_id);
    }
    return null;
  }, [user]);

  // set initial default blank form values
  const initialBlankValues = {
    attributionAssessment: '',
    source: '',
    sourcesInput: '',
    observablesType: '',
    observablesValue: '',
    observablesDate: '',
    observablesIoc: 'false',
    attributionAssessments: [],
    sources: [],
    observables: [],
    sectors: [],
    sourceCode: sourceCountry.key ?? user.country_id,
    technicalName: '',
    technicalEmail: '',
    summary: '',
    incidentStart: format(new Date(), 'yyyy-MM-dd'),
    incidentEnd: '',
    severity: '',
    acceptTerms: false
  };

  const handlePushMultiValue = (
    fieldName,
    values,
    errors,
    arrayHelpers,
    setFieldValue,
    setErrors
  ) => {
    // if we have a value in the field
    if (values[fieldName].length) {
      // push it onto the array
      arrayHelpers.push(values[fieldName]);
      setFieldValue(fieldName, initialBlankValues[fieldName]);
    } else if (!values[arrayHelpers.name].length) {
      // else if there's nothing in the field
      // and if there's nothing in the array
      // show an error message
      setErrors({
        ...{ [fieldName]: 'Please Enter a Value. ' },
        ...errors
      });
    }
  };

  // Populate the sectors/subsectors in the sector control
  const populateSectorMenu = (values) => {
    const menuItemArray = [];
    sectors.forEach((sector) => {
      if (sector.subsectors.length > 0) {
        menuItemArray.push(
          <SectorSubSectors key={sector.value} value={sector.value}>
            {sector.label}
          </SectorSubSectors>
        );
        if (values.sectors.includes(sector.value)) {
          sector.subsectors.forEach((subsector) => {
            menuItemArray.push(
              <SubSectors
                key={subsector.value}
                value={subsector.value}
              >{`${subsector.label}`}</SubSectors>
            );
          });
        }
      } else {
        menuItemArray.push(
          <UppercaseValues key={sector.value} value={sector.value}>
            {sector.label}
          </UppercaseValues>
        );
      }
    });
    return menuItemArray;
  };

  const formik = useFormik({
    initialValues: initialBlankValues,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError('');

      // Test for Valid input on Observables Fields and add to input if found...
      // Just simple existence for now, could be integrated into Formik/Yup later
      // Just in case they filled it out and didn't hit add
      if (
        values.observablesType.length &&
        values.observablesValue.length &&
        values.observablesDate.length &&
        values.observablesIoc.length
      ) {
        values.observables.push({
          type: values.observablesType,
          value: values.observablesValue,
          dateObserved: values.observablesDate,
          isIoc: values.observablesIoc === 'true' // boolean,
        });
      }

      // Process Sectors/Subsectors
      const sectorOutput = [];
      // look through the sectors for matches
      sectors.forEach((sector) => {
        const tempSector = { value: '', subsectors: [] };
        if (values.sectors.includes(sector.value)) {
          tempSector.value = sector.value;
          // if the sector has subsectors
          if (sector.subsectors.length > 0) {
            sector.subsectors.forEach((subsector) => {
              if (values.sectors.includes(subsector.value)) {
                tempSector.subsectors.push(subsector.value);
              }
            });
          }
        }
        // if we found a sector, add it to the output
        if (tempSector.value.length > 0) sectorOutput.push(tempSector);
      });

      // create field data variables from form
      const fieldDataVariables = {
        sectors: sectorOutput,
        severity: values.severity,
        incidentStart: `${values.incidentStart} 00:00`, // add time to fix time zone wonkiness
        incidentEnd:
          // if the field isn't touched, it might not be in the values object. Check for it.
          Object.prototype.hasOwnProperty.call(values, 'incidentEnd') &&
          values.incidentEnd.toString().length
            ? `${values.incidentEnd} 00:00`
            : '',
        threatVector: values.threatVector?.trim(),
        summary: values.summary?.trim(),
        sources: values.sources.map((s) => s?.trim()),
        attributionAssessments: values.attributionAssessments.map((a) =>
          a?.trim()
        ),
        observables: values.observables.map((o) => ({
          ...o,
          value: o.value?.trim()
        })),
        poc: {
          technicalName: values.technicalName?.trim(),
          technicalEmail: values.technicalEmail?.trim()
        },
        source: values.sourceCode
      };

      // iniate new form data
      const data = new FormData();

      data.append('country_id', values.sourceCode);
      data.append('field_data', JSON.stringify(fieldDataVariables));
      data.append('malware_count', uploadFiles.length);

      if (uploadFiles.length > 0) {
        uploadFiles.forEach((file) => {
          data.append('files', file, file.name);
        });
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_ACCESS_POINT}/report`,
          data,
          {
            headers: {
              'application-context': 'spp'
            },
            withCredentials: true
          }
        );

        if (response) {
          formik.setSubmitting(false);
          setLoading(false);
          logoutUser();
          navigate(`/success`, { replace: true });
        }
      } catch (error) {
        console.log(error);
        formik.setSubmitting(false);
        setLoading(false);
        setError('Error Creating Cyber Incident Report');
      }
    }
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    setErrors,
    setTouched
  } = formik;

  const collectFiles = (files) => {
    setUploadFiles(files);
  };

  // render the data only if it's in the sectors object
  // and for subsectors, only if its sector is selected
  const renderSectorValue = (v) => {
    if (v.length === 0) return '';
    const valueArray = [];
    sectors.forEach((sector) => {
      if (values.sectors.includes(sector.value)) {
        valueArray.push(sector.label);
        if (sector.subsectors.length > 0)
          sector.subsectors.forEach((subsector) => {
            if (values.sectors.includes(subsector.value))
              valueArray.push(subsector.label);
          });
      }
    });
    return valueArray.join(', ');
  };

  // Keep the dropdown menus from being too tall
  const MenuProps = { PaperProps: { style: { maxHeight: '400px' } } };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          rowSpacing={{ xs: 2, sm: 1 }}
          columnSpacing={{ xs: 3, sm: 3 }}
        >
          <Grid direction="column" alignItems="flex-start" xs={12} sm={12}>
            <BoldText variant="h6">About This Report:</BoldText>
            <FormControl fullWidth margin="normal" required variant="standard">
              <InputLabel
                id="sourceCodeLabel"
                error={touched.sourceCode && Boolean(errors.sourceCode)}
              >
                Source
              </InputLabel>
              <Input
                id="sourceCode"
                variant="standard"
                value={`${sourceCountry.key} - ${sourceCountry.description}`}
                placeholder={sourceCountry.description}
                name="sourceCode"
                inputProps={{
                  'data-testid': 'reportform-sourcecode'
                }}
                required
                disabled
                error={touched.sourceCode && Boolean(errors.sourceCode)}
              />
              <FormHelperText
                error={touched.sourceCode && Boolean(errors.sourceCode)}
              >
                {touched.sourceCode ? errors.sourceCode : ''}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth margin="normal" required variant="standard">
              <InputLabel
                id="sectors-select"
                htmlFor="sectors"
                required
                error={touched.sectors && Boolean(errors.sectors)}
              >
                Sectors (Select Multiple if Applicable)
              </InputLabel>
              <UppercaseSelect
                id="sectors"
                name="sectors"
                multiple
                value={values.sectors}
                onChange={handleChange}
                onBlur={handleBlur}
                onClick={() => {
                  setTouched({ sectors: true, ...touched }, false);
                }}
                inputProps={{
                  'data-testid': 'reportform-select-sectors'
                }}
                input={
                  <Input label="Sectors (Select Multiple if Applicable)" />
                }
                error={touched.sectors && Boolean(errors.sectors)}
                MenuProps={MenuProps}
                displayEmpty
                renderValue={renderSectorValue}
              >
                {populateSectorMenu(values)}
              </UppercaseSelect>
              <FormHelperText
                error={touched.sectors && Boolean(errors.sectors)}
              >
                {touched.sectors ? errors.sectors : ''}
              </FormHelperText>
            </FormControl>
            <TextField
              id="severity"
              select
              fullWidth
              required
              variant="standard"
              margin="normal"
              label="Severity"
              name="severity"
              value={values.severity}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.severity && Boolean(errors.severity)}
              helperText={touched.severity ? errors.severity : ''}
              SelectProps={{ MenuProps }}
              inputProps={{
                'data-testid': 'reportform-select-severity'
              }}
            >
              {severityRatings.map((rating) => (
                <UppercaseValues value={rating.value} key={rating.value}>
                  {rating.label}
                </UppercaseValues>
              ))}
            </TextField>
          </Grid>
          <Grid xs={12} sm={12}>
            <BoldText variant="h6" sx={{ marginTop: 3 }}>
              Incident Context:
            </BoldText>
            <HighContrastTooltip
              title="Earliest suspected or known start date the activity described in this incident began."
              placement="top"
            >
              <TextField
                required
                fullWidth
                margin="normal"
                variant="standard"
                name="incidentStart"
                label="Incident Start Date"
                type="date"
                value={values.incidentStart}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  'data-testid': 'reportform-input-incidentstart',
                  max: getCurrentDateForInput()
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.incidentStart && Boolean(errors.incidentStart)}
                helperText={touched.incidentStart ? errors.incidentStart : ''}
              />
            </HighContrastTooltip>
            <HighContrastTooltip
              title="Latest suspected or known end date the activity described in this incident ended. If this event is ongoing, leave blank."
              placement="top"
            >
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                name="incidentEnd"
                label="Incident End Date (Blank for Ongoing)"
                type="date"
                value={values.incidentEnd}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  'data-testid': 'reportform-input-incidentend',
                  max: getCurrentDateForInput()
                }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.incidentEnd && Boolean(errors.incidentEnd)}
                helperText={touched.incidentEnd ? errors.incidentEnd : ''}
              />
            </HighContrastTooltip>
            <Box mt={3}>
              <BoldText gutterBottom>Threat Vector:</BoldText>
              <InfoText>
                Suspected or known method employed by the threat actor to gain
                initial access to the system(s) addressed in this incident.
              </InfoText>
              <FormControl fullWidth>
                <TextField
                  id="threatVector"
                  name="threatVector"
                  type="text"
                  placeholder="e.g. Reconnaissance > Information Gathering"
                  label="Select Method"
                  style={{ cursor: 'pointer' }}
                  required
                  fullWidth
                  variant="standard"
                  margin="dense"
                  value={values.threatVector}
                  onClick={() => {
                    setThreatVectorMenuOpen((prev) => !prev);
                  }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    ref: threatVectorElement,
                    'data-testid': 'reportform-input-threatvector',
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        style={{ cursor: 'pointer' }}
                        position="end"
                      >
                        {threatVectorMenuOpen ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}
                      </InputAdornment>
                    ),
                    inputProps: {
                      style: { cursor: 'pointer' }
                    }
                  }}
                  error={
                    touched.threatVector &&
                    !!errors.threatVector &&
                    !threatVectorMenuOpen
                  }
                  helperText={
                    touched.threatVector && !threatVectorMenuOpen
                      ? errors.threatVector
                      : ''
                  }
                />
                <Popover
                  open={threatVectorMenuOpen}
                  anchorEl={threatVectorElement.current}
                  onClose={() => {
                    setThreatVectorMenuOpen(false);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  <ThreatVectorList
                    style={{
                      width: threatVectorElement?.current?.offsetWidth
                    }}
                  >
                    {!!threatVectors.length &&
                      threatVectors.map((threatVector) => (
                        <Fragment key={threatVector.name}>
                          <ListItemButton
                            selected={
                              threatVectorSelected.name === threatVector.name
                            }
                            onClick={() => {
                              // if we're re-clicking the same one, clear it
                              // if we're clicking a new one, change it and clear sub
                              const nameValue =
                                threatVectorSelected.name === threatVector.name
                                  ? ''
                                  : threatVector.name;
                              // update current selected threatVector
                              setThreatVectorSelected({
                                name: nameValue,
                                subVector: { name: '' }
                              });
                              // update Formik value for field
                              setFieldValue('threatVector', nameValue);
                              // if there aren't any subVectors
                              if (!threatVector?.subVectors?.length)
                                // close the menu
                                setThreatVectorMenuOpen(false);
                            }}
                          >
                            <ListItemText primary={threatVector.name} />
                            {!!threatVector?.subVectors?.length &&
                              (threatVectorSelected.name ===
                              threatVector.name ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              ))}
                          </ListItemButton>
                          {!!threatVector?.subVectors?.length && (
                            <Collapse
                              in={
                                threatVectorSelected.name === threatVector.name
                              }
                              unmountOnExit
                            >
                              <SubVectorList>
                                {threatVector.subVectors.map((subVector) => (
                                  <SubVector
                                    key={subVector.name}
                                    button
                                    selected={
                                      subVector.name ===
                                      threatVectorSelected.subVector.name
                                    }
                                    onClick={() => {
                                      // update threat vector selections
                                      setThreatVectorSelected({
                                        name: threatVector.name,
                                        subVector: {
                                          name: subVector.name
                                        }
                                      });
                                      // set the formik field value
                                      setFieldValue(
                                        'threatVector',
                                        `${threatVector.name} > ${subVector.name}`
                                      );
                                      // close the menu
                                      setThreatVectorMenuOpen(false);
                                    }}
                                  >
                                    <ListItemText primary={subVector.name} />
                                  </SubVector>
                                ))}
                              </SubVectorList>
                            </Collapse>
                          )}
                        </Fragment>
                      ))}
                  </ThreatVectorList>
                </Popover>
              </FormControl>
            </Box>
            <Box mt={3}>
              <BoldText gutterBottom>Attribution Assessments:</BoldText>
              <InfoText>
                Provide an assessment of threat actor group or nation that
                conducted the activity (if suspected or known).
              </InfoText>
              <FormControl fullWidth>
                <AssessmentLabel
                  htmlFor="attributionAssessments"
                  required
                  error={
                    (touched.attributionAssessment &&
                      !!errors.attributionAssessment) ||
                    (touched.attributionAssessment &&
                      !!errors.attributionAssessments)
                  }
                >
                  Add Actor Group
                </AssessmentLabel>
                <FieldArray
                  name="attributionAssessments"
                  render={(arrayHelpers) => (
                    <div>
                      {values.attributionAssessments.map((item, index) => (
                        <Container
                          // eslint-disable-next-line react/no-array-index-key
                          key={`attributionAssessments${index}`}
                        >
                          <ArrayText
                            name={`attributionAssessments[${index}]`}
                            component="p"
                          >
                            {item}
                          </ArrayText>
                          <HighContrastTooltip
                            title="Remove Attribution"
                            placement="left"
                          >
                            <DeleteIcon
                              onClick={() => {
                                setTouched(
                                  {
                                    attributionAssessment: true,
                                    ...touched
                                  },
                                  true
                                );
                                return arrayHelpers.remove(index);
                              }}
                              color="primary"
                              data-testid="reportform-button-removeattribution"
                            />
                          </HighContrastTooltip>
                        </Container>
                      ))}
                      <Field name="attributionAssessment">
                        {({ form }) => {
                          return (
                            <InputWrapper>
                              <CustomInput
                                margin="dense"
                                name="attributionAssessment"
                                value={form.values.attributionAssessment}
                                placeholder="e.g. APT29, Cozy Bear"
                                fullWidth
                                error={
                                  (touched.attributionAssessment &&
                                    !!errors.attributionAssessment) ||
                                  (touched.attributionAssessment &&
                                    !!errors.attributionAssessments)
                                }
                                onChange={handleChange}
                                onBlur={(e) => {
                                  setTouched(
                                    {
                                      attributionAssessment: true,
                                      ...touched
                                    },
                                    true
                                  );
                                  if (!errors.attributionAssessment) {
                                    handlePushMultiValue(
                                      e.target.name,
                                      form.values,
                                      form.errors,
                                      arrayHelpers,
                                      form.setFieldValue,
                                      form.setErrors
                                    );
                                  }
                                }}
                                onKeyPress={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    if (!errors.attributionAssessment) {
                                      handlePushMultiValue(
                                        e.target.name,
                                        form.values,
                                        form.errors,
                                        arrayHelpers,
                                        form.setFieldValue,
                                        form.setErrors
                                      );
                                    }
                                  }
                                }}
                              />
                              <HighContrastTooltip title="Add New Attribution">
                                <AddIcon
                                  data-testid="reportform-button-addattribution"
                                  onClick={() => {
                                    setTouched(
                                      {
                                        attributionAssessment: true,
                                        ...touched
                                      },
                                      true
                                    );
                                    if (!errors.attributionAssessment) {
                                      handlePushMultiValue(
                                        'attributionAssessment',
                                        values,
                                        errors,
                                        arrayHelpers,
                                        setFieldValue,
                                        setErrors
                                      );
                                    }
                                  }}
                                  color="primary"
                                />
                              </HighContrastTooltip>
                            </InputWrapper>
                          );
                        }}
                      </Field>
                    </div>
                  )}
                />
                <FormHelperText variant="standard" error>
                  {touched.attributionAssessment &&
                    (errors.attributionAssessment ||
                      errors.attributionAssessments ||
                      '')}
                </FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <Box pt={3} pb={3}>
          <BoldText gutterBottom>Summary:</BoldText>
          <InfoText gutterBottom>
            Describe the incident in as much detail you have available at the
            time of this report. Include all on-network observations about the
            threat actor and the impact of the incident. If the incident
            involves more than one sector or organization, please make clear
            what has occurred in each. If the incident involves malware, include
            the number of unique instances based on hash (MD5 or SHA256) and the
            suspected or known name(s) of the malware.
          </InfoText>
          <TextField
            id="summary"
            name="summary"
            label="Give a Description of the Event"
            multiline
            rows={4}
            required
            margin="normal"
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.summary ? errors.summary : ''}
            error={touched.summary && Boolean(errors.summary)}
            inputProps={{
              'data-testid': 'reportform-input-summary'
            }}
          />
        </Box>
        <Grid xs={12} sm={12}>
          <BoldText gutterBottom>Sources Already Queried:</BoldText>
          <InfoText>
            Add Additional Tools Used, i.e. VirusTotal. Type a value, then press
            Enter or click + to add.
          </InfoText>
          <FormControl fullWidth>
            <FieldArray
              name="sources"
              render={(arrayHelpers) => (
                <div>
                  {values.sources.map((item, index) => (
                    <Container
                      // eslint-disable-next-line react/no-array-index-key
                      key={`sources${index}`}
                    >
                      <ArrayText name={`sources[${index}]`} component="p">
                        {item}
                      </ArrayText>
                      <HighContrastTooltip
                        title="Remove Source"
                        placement="left"
                      >
                        <DeleteIcon
                          onClick={() => arrayHelpers.remove(index)}
                          color="primary"
                        />
                      </HighContrastTooltip>
                    </Container>
                  ))}
                  <Field name="sourcesInput">
                    {({ form }) => {
                      return (
                        <InputWrapper>
                          <CustomInput
                            margin="dense"
                            name="sourcesInput"
                            value={form.values.sourcesInput}
                            fullWidth
                            onChange={form.handleChange}
                            onBlur={(e) => {
                              if (!errors.sourcesInput) {
                                handlePushMultiValue(
                                  e.target.name,
                                  form.values,
                                  form.errors,
                                  arrayHelpers,
                                  form.setFieldValue,
                                  form.setErrors
                                );
                              }
                            }}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                if (!errors.sourcesInput) {
                                  handlePushMultiValue(
                                    e.target.name,
                                    form.values,
                                    form.errors,
                                    arrayHelpers,
                                    form.setFieldValue,
                                    form.setErrors
                                  );
                                }
                              }
                            }}
                            error={
                              (form.touched.sourcesInput &&
                                Boolean(form.errors.sourcesInput)) ||
                              Boolean(form.errors.sourcesInput)
                            }
                            inputProps={{
                              'data-testid': 'reportform-input-sources',
                              'aria-label': 'New Source Text Area'
                            }}
                          />
                          <HighContrastTooltip
                            title="Add New Source"
                            placement="right"
                          >
                            <AddIcon
                              data-testid="reportform-button-addsource"
                              onClick={() => {
                                if (!errors.sourcesInput) {
                                  handlePushMultiValue(
                                    'sourcesInput',
                                    values,
                                    errors,
                                    arrayHelpers,
                                    setFieldValue,
                                    setErrors
                                  );
                                }
                              }}
                              color="primary"
                            />
                          </HighContrastTooltip>
                        </InputWrapper>
                      );
                    }}
                  </Field>
                </div>
              )}
            />
            <FormHelperText variant="standard" error>
              {errors.sourcesInput}
              {touched.sourcesInput ? errors.sourcesInput : ''}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Box py={3}>
          <BoldText gutterBottom>Indicators of Compromise (IOC):</BoldText>
          <InfoText gutterBottom>
            List IOCs by type and value. Examples include IP addresses, hash
            values, domains and subdomains, registry key values, etc.
          </InfoText>
          <Table>
            <TableHead>
              <TableRow>
                <ObservablesCell id="ioc-observable-type">Type</ObservablesCell>
                <ObservablesCell id="ioc-observable-value">
                  Value
                </ObservablesCell>
                <ObservablesCell id="ioc-observable-date">Date</ObservablesCell>
                <ObservablesCell id="ioc-confirm-select">IOC?</ObservablesCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <FieldArray
              name="observables"
              render={(arrayHelpers) => (
                <TableBody>
                  {values.observables.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={`observables${index}`}
                    >
                      <ObservablesCell>{item.type}</ObservablesCell>
                      <ObservablesCell>{item.value}</ObservablesCell>
                      <ObservablesCell>{item.dateObserved}</ObservablesCell>
                      <ObservablesCell>
                        {item.isIoc ? 'Yes' : 'No'}
                      </ObservablesCell>
                      <TableCell>
                        <HighContrastTooltip title="Remove IOC or Observable">
                          <Delete
                            onClick={() => arrayHelpers.remove(index)}
                            color="primary"
                          />
                        </HighContrastTooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <ObservablesCell>
                      <ArrayFieldFullWidth
                        name="observablesType"
                        component={NativeSelect}
                        error={Boolean(errors.observablesType)}
                        inputProps={{
                          name: 'observablesType',
                          value: values.observablesType,
                          'aria-labelledby': 'ioc-observable-type',
                          'data-testid': 'ioc-observable-type',
                          onChange: (e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }
                        }}
                      >
                        {observablesTypes.map((type) => (
                          <option value={type.value} key={type.value}>
                            {type.label}
                          </option>
                        ))}
                      </ArrayFieldFullWidth>
                    </ObservablesCell>
                    <ObservablesCell>
                      <HighContrastTooltip
                        title="Provide Specific Indicator of the Selected Type"
                        placement="top"
                      >
                        <ArrayFieldFullWidth
                          name="observablesValue"
                          component={Input}
                          error={Boolean(errors.observablesValue)}
                          inputProps={{
                            name: 'observablesValue',
                            'data-testid': 'ioc-observable-value',
                            'aria-labelledby': 'ioc-observable-value',
                            defaultValue: initialBlankValues.observablesValue,
                            ref: observableValueInputRef,
                            onBlur: (e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }
                          }}
                        />
                      </HighContrastTooltip>
                    </ObservablesCell>
                    <ObservablesCell>
                      <ArrayFieldFullWidth
                        name="observablesDate"
                        component={Input}
                        error={Boolean(errors.observablesDate)}
                        inputProps={{
                          type: 'date',
                          variant: 'standard',
                          name: 'observablesDate',
                          value: values.observablesDate,
                          'aria-labelledby': 'ioc-observable-date',
                          'data-testid': 'ioc-observable-date',
                          onChange: (e) => {
                            setFieldValue(e.target.name, e.target.value);
                          }
                        }}
                      />
                    </ObservablesCell>
                    <ObservablesCell>
                      <HighContrastTooltip
                        title="Is this an Indicator of Compromise?"
                        placement="top"
                      >
                        <ArrayFieldFullWidth
                          name="observablesIoc"
                          component={NativeSelect}
                          error={Boolean(errors.observablesIoc)}
                          inputProps={{
                            name: 'observablesIoc',
                            value: values.observablesIoc,
                            'aria-labelledby': 'ioc-confirm-select',
                            onChange: (e) => {
                              setFieldValue(e.target.name, e.target.value);
                            }
                          }}
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </ArrayFieldFullWidth>
                      </HighContrastTooltip>
                    </ObservablesCell>
                    <ObservablesCell>
                      <HighContrastTooltip title="Add IOC/Observable to List">
                        <Add
                          onClick={() => {
                            if (
                              values.observablesType.length &&
                              values.observablesValue.length &&
                              values.observablesDate.length &&
                              !errors.observablesValue
                            ) {
                              arrayHelpers.push({
                                type: values.observablesType,
                                value: values.observablesValue,
                                dateObserved: values.observablesDate,
                                isIoc: values.observablesIoc === 'true' // boolean,
                              });
                              setFieldValue(
                                'observablesType',
                                initialBlankValues.observablesType
                              );
                              setFieldValue(
                                'observablesValue',
                                initialBlankValues.observablesValue
                              );
                              // used to reset observable value input after clicking add new observable
                              observableValueInputRef.current.value =
                                initialBlankValues.observablesValue;
                              setFieldValue(
                                'observablesDate',
                                initialBlankValues.observablesDate
                              );
                              setFieldValue(
                                'observablesIoc',
                                initialBlankValues.observablesIoc
                              );
                            } else {
                              const observablesErrorMessages = {};
                              if (!values.observablesType.length) {
                                observablesErrorMessages.observablesType =
                                  'Select a Type';
                              }
                              if (!values.observablesDate.length) {
                                observablesErrorMessages.observablesDate =
                                  'Select a Date';
                              }
                              if (!values.observablesValue.length) {
                                observablesErrorMessages.observablesValue =
                                  'Enter a Value';
                              }
                              setErrors({
                                ...observablesErrorMessages,
                                ...errors
                              });
                            }
                          }}
                          color="primary"
                        />
                      </HighContrastTooltip>
                    </ObservablesCell>
                  </TableRow>
                  <TableRow>
                    <ObservablesCell xs={{ height: 'auto' }}>
                      <FormHelperText error>
                        {errors.observablesType}
                      </FormHelperText>
                    </ObservablesCell>
                    <ObservablesCell>
                      <FormHelperText error>
                        {errors.observablesValue}
                      </FormHelperText>
                    </ObservablesCell>
                    <ObservablesCell>
                      <FormHelperText error>
                        {errors.observablesDate}
                      </FormHelperText>
                    </ObservablesCell>
                    <ObservablesCell />
                    <ObservablesCell />
                  </TableRow>
                </TableBody>
              )}
            />
          </Table>
          {touched.observables ? errors.observables : ''}
        </Box>
        <BoldText>Upload Malware and IOC Files:</BoldText>
        <UploadButton fullWidth onClick={handleOpenUpload} variant="outlined">
          Upload Malware and IOC Files {`(${uploadFiles.length})`}
        </UploadButton>
        <Box pb={3}>
          <BoldText display="block" gutterBottom>
            Point of Contact:
          </BoldText>
          <InfoText>Provide Accessible Point of Contact Information</InfoText>
        </Box>
        <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <TextField
              id="technicalName"
              name="technicalName"
              type="text"
              label="Technical Point-of-Contact Name"
              required
              fullWidth
              value={values.technicalName}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.technicalName ? errors.technicalName : ''}
              error={touched.technicalName && Boolean(errors.technicalName)}
              inputProps={{
                'data-testid': 'reportform-input-technicalname'
              }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              id="technicalEmail"
              name="technicalEmail"
              type="text"
              label="Technical Point-of-Contact Email"
              required
              fullWidth
              value={values.technicalEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.technicalEmail ? errors.technicalEmail : ''}
              error={touched.technicalEmail && Boolean(errors.technicalEmail)}
              inputProps={{
                'data-testid': 'reportform-input-technicalemail'
              }}
            />
          </Grid>
        </Grid>
        <Box my={3}>
          <CheckBoxFormLabel
            control={
              <CheckBox
                inputProps={{
                  'aria-label': 'Accept Submit Terms',
                  'data-testid': 'accept-terms-check'
                }}
                sx={{
                  '&:hover': { bgcolor: 'transparent' }
                }}
                disableRipple
              />
            }
            name="acceptTerms"
            onChange={handleChange}
            label="The information you are about to submit will be shared with HQ Cyber and its partners. Please confirm that you are NOT sharing Personally Identifiable Information, Protected Health Information, Classified, or Sensitive Information."
          />
        </Box>

        {error && (
          <Alert severity="error" variant="filled">
            {error}
          </Alert>
        )}

        <Button
          type="submit"
          variant="contained"
          disabled={!isValid || isSubmitting}
          color="primary"
          fullWidth
        >
          {loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Submit Cyber Incident Report'
          )}
        </Button>
        {openUpload && (
          <Suspense fallback="">
            <FileUpload
              open={openUpload}
              handleClose={handleCloseUpload}
              collectFiles={collectFiles}
              parentFiles={uploadFiles}
            />
          </Suspense>
        )}
      </form>
    </FormikProvider>
  );
};

export default ReportForm;
