const relTos = [
  {
    key: 'USA',
    description: 'UNITED STATES OF AMERICA',
    parentKey: null
  },
  {
    key: 'AUS',
    description: 'AUSTRALIA',
    parentKey: null
  },
  {
    key: 'CAN',
    description: 'CANADA',
    parentKey: null
  },
  {
    key: 'GBR',
    description: 'GREAT BRITAIN AND NORTHERN IRELAND',
    parentKey: null
  },
  {
    key: 'NZL',
    description: 'NEW ZEALAND',
    parentKey: null
  },
  {
    key: 'ABW',
    description: 'ARUBA',
    parentKey: null
  },
  {
    key: 'AFG',
    description: 'AFGHANISTAN',
    parentKey: null
  },
  {
    key: 'AGO',
    description: 'ANGOLA',
    parentKey: null
  },
  {
    key: 'AIA',
    description: 'ANGUILLA',
    parentKey: null
  },
  {
    key: 'ALA',
    description: 'ALAND ISLANDS',
    parentKey: null
  },
  {
    key: 'ALB',
    description: 'ALBANIA',
    parentKey: null
  },
  {
    key: 'AND',
    description: 'ANDORRA',
    parentKey: null
  },
  {
    key: 'ARE',
    description: 'UNITED ARAB EMIRATES',
    parentKey: null
  },
  {
    key: 'ARG',
    description: 'ARGENTINA',
    parentKey: null
  },
  {
    key: 'ARM',
    description: 'ARMENIA',
    parentKey: null
  },
  {
    key: 'ASM',
    description: 'AMERICAN SAMOA',
    parentKey: null
  },
  {
    key: 'ATA',
    description: 'ANTARCTICA',
    parentKey: null
  },
  {
    key: 'ATF',
    description: 'FRENCH SOUTHERN TERRITORIES',
    parentKey: null
  },
  {
    key: 'ATG',
    description: 'ANTIGUA AND BARBUDA',
    parentKey: null
  },
  {
    key: 'AUT',
    description: 'AUSTRIA',
    parentKey: null
  },
  {
    key: 'AZE',
    description: 'AZERBAIJAN',
    parentKey: null
  },
  {
    key: 'BDI',
    description: 'BURUNDI',
    parentKey: null
  },
  {
    key: 'BEL',
    description: 'BELGIUM',
    parentKey: null
  },
  {
    key: 'BEN',
    description: 'BENIN',
    parentKey: null
  },
  {
    key: 'BES',
    description: 'BONAIRE, SINT EUSTATIUS AND SABA',
    parentKey: null
  },
  {
    key: 'BFA',
    description: 'BURKINA FASO',
    parentKey: null
  },
  {
    key: 'BGD',
    description: 'BANGLADESH',
    parentKey: null
  },
  {
    key: 'BGR',
    description: 'BULGARIA',
    parentKey: null
  },
  {
    key: 'BHR',
    description: 'BAHRAIN',
    parentKey: null
  },
  {
    key: 'BHS',
    description: 'BAHAMAS',
    parentKey: null
  },
  {
    key: 'BIH',
    description: 'BOSNIA AND HERZEGOVINA',
    parentKey: null
  },
  {
    key: 'BLM',
    description: 'SAINT BARTHELEMY',
    parentKey: null
  },
  {
    key: 'BLR',
    description: 'BELARUS',
    parentKey: null
  },
  {
    key: 'BLZ',
    description: 'BELIZE',
    parentKey: null
  },
  {
    key: 'BMU',
    description: 'BERMUDA',
    parentKey: null
  },
  {
    key: 'BOL',
    description: 'BOLIVIA (PLURINATIONAL STATE OF)',
    parentKey: null
  },
  {
    key: 'BRA',
    description: 'BRAZIL',
    parentKey: null
  },
  {
    key: 'BRB',
    description: 'BARBADOS',
    parentKey: null
  },
  {
    key: 'BRN',
    description: 'BRUNEI DARUSSALAM',
    parentKey: null
  },
  {
    key: 'BTN',
    description: 'BHUTAN',
    parentKey: null
  },
  {
    key: 'BVT',
    description: 'BOUVET ISLAND',
    parentKey: null
  },
  {
    key: 'BWA',
    description: 'BOTSWANA',
    parentKey: null
  },
  {
    key: 'CAF',
    description: 'CENTRAL AFRICAN REPUBLIC',
    parentKey: null
  },
  {
    key: 'CCK',
    description: 'COCOS (KEELING) ISLANDS',
    parentKey: null
  },
  {
    key: 'CHE',
    description: 'SWITZERLAND',
    parentKey: null
  },
  {
    key: 'CHL',
    description: 'CHILE',
    parentKey: null
  },
  {
    key: 'CHN',
    description: 'CHINA',
    parentKey: null
  },
  {
    key: 'CIV',
    description: "COTE D'IVOIRE",
    parentKey: null
  },
  {
    key: 'CMR',
    description: 'CAMEROON',
    parentKey: null
  },
  {
    key: 'COD',
    description: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
    parentKey: null
  },
  {
    key: 'COG',
    description: 'CONGO',
    parentKey: null
  },
  {
    key: 'COK',
    description: 'COOK ISLANDS',
    parentKey: null
  },
  {
    key: 'COL',
    description: 'COLOMBIA',
    parentKey: null
  },
  {
    key: 'COM',
    description: 'COMOROS',
    parentKey: null
  },
  {
    key: 'CPV',
    description: 'CABO VERDE',
    parentKey: null
  },
  {
    key: 'CRI',
    description: 'COSTA RICA',
    parentKey: null
  },
  {
    key: 'CUB',
    description: 'CUBA',
    parentKey: null
  },
  {
    key: 'CUW',
    description: 'CURACAO',
    parentKey: null
  },
  {
    key: 'CXR',
    description: 'CHRISTMAS ISLAND',
    parentKey: null
  },
  {
    key: 'CYM',
    description: 'CAYMAN ISLANDS',
    parentKey: null
  },
  {
    key: 'CYP',
    description: 'CYPRUS',
    parentKey: null
  },
  {
    key: 'CZE',
    description: 'CZECH REPUBLIC',
    parentKey: null
  },
  {
    key: 'DEU',
    description: 'GERMANY',
    parentKey: null
  },
  {
    key: 'DJI',
    description: 'DJIBOUTI',
    parentKey: null
  },
  {
    key: 'DMA',
    description: 'DOMINICA',
    parentKey: null
  },
  {
    key: 'DNK',
    description: 'DENMARK',
    parentKey: null
  },
  {
    key: 'DOM',
    description: 'DOMINICAN REPUBLIC',
    parentKey: null
  },
  {
    key: 'DZA',
    description: 'ALGERIA',
    parentKey: null
  },
  {
    key: 'ECU',
    description: 'ECUADOR',
    parentKey: null
  },
  {
    key: 'EGY',
    description: 'EGYPT',
    parentKey: null
  },
  {
    key: 'ERI',
    description: 'ERITREA',
    parentKey: null
  },
  {
    key: 'ESH',
    description: 'WESTERN SAHARA',
    parentKey: null
  },
  {
    key: 'ESP',
    description: 'SPAIN',
    parentKey: null
  },
  {
    key: 'EST',
    description: 'ESTONIA',
    parentKey: null
  },
  {
    key: 'ETH',
    description: 'ETHIOPIA',
    parentKey: null
  },
  {
    key: 'FIN',
    description: 'FINLAND',
    parentKey: null
  },
  {
    key: 'FJI',
    description: 'FIJI',
    parentKey: null
  },
  {
    key: 'FLK',
    description: 'FALKLAND ISLANDS (MALVINAS)',
    parentKey: null
  },
  {
    key: 'FRA',
    description: 'FRANCE',
    parentKey: null
  },
  {
    key: 'FRO',
    description: 'FAROE ISLANDS',
    parentKey: null
  },
  {
    key: 'FSM',
    description: 'MICRONESIA (FEDERATED STATES OF)',
    parentKey: null
  },
  {
    key: 'GAB',
    description: 'GABON',
    parentKey: null
  },
  {
    key: 'GEO',
    description: 'GEORGIA',
    parentKey: null
  },
  {
    key: 'GGY',
    description: 'GUERNSEY',
    parentKey: null
  },
  {
    key: 'GHA',
    description: 'GHANA',
    parentKey: null
  },
  {
    key: 'GIB',
    description: 'GIBRALTAR',
    parentKey: null
  },
  {
    key: 'GIN',
    description: 'GUINEA',
    parentKey: null
  },
  {
    key: 'GLP',
    description: 'GUADELOUPE',
    parentKey: null
  },
  {
    key: 'GMB',
    description: 'GAMBIA',
    parentKey: null
  },
  {
    key: 'GNB',
    description: 'GUINEA-BISSAU',
    parentKey: null
  },
  {
    key: 'GNQ',
    description: 'EQUATORIAL GUINEA',
    parentKey: null
  },
  {
    key: 'GRC',
    description: 'GREECE',
    parentKey: null
  },
  {
    key: 'GRD',
    description: 'GRENADA',
    parentKey: null
  },
  {
    key: 'GRL',
    description: 'GREENLAND',
    parentKey: null
  },
  {
    key: 'GTM',
    description: 'GUATEMALA',
    parentKey: null
  },
  {
    key: 'GUF',
    description: 'FRENCH GUIANA',
    parentKey: null
  },
  {
    key: 'GUM',
    description: 'GUAM',
    parentKey: null
  },
  {
    key: 'GUY',
    description: 'GUYANA',
    parentKey: null
  },
  {
    key: 'HKG',
    description: 'HONG KONG',
    parentKey: null
  },
  {
    key: 'HMD',
    description: 'HEARD ISLAND AND MCDONALD ISLANDS',
    parentKey: null
  },
  {
    key: 'HND',
    description: 'HONDURAS',
    parentKey: null
  },
  {
    key: 'HRV',
    description: 'CROATIA',
    parentKey: null
  },
  {
    key: 'HTI',
    description: 'HAITI',
    parentKey: null
  },
  {
    key: 'HUN',
    description: 'HUNGARY',
    parentKey: null
  },
  {
    key: 'IDN',
    description: 'INDONESIA',
    parentKey: null
  },
  {
    key: 'IMN',
    description: 'ISLE OF MAN',
    parentKey: null
  },
  {
    key: 'IND',
    description: 'INDIA',
    parentKey: null
  },
  {
    key: 'IOT',
    description: 'BRITISH INDIAN OCEAN TERRITORY',
    parentKey: null
  },
  {
    key: 'IRL',
    description: 'IRELAND',
    parentKey: null
  },
  {
    key: 'IRN',
    description: 'IRAN (ISLAMIC REPUBLIC OF)',
    parentKey: null
  },
  {
    key: 'IRQ',
    description: 'IRAQ',
    parentKey: null
  },
  {
    key: 'ISL',
    description: 'ICELAND',
    parentKey: null
  },
  {
    key: 'ISR',
    description: 'ISRAEL',
    parentKey: null
  },
  {
    key: 'ITA',
    description: 'ITALY',
    parentKey: null
  },
  {
    key: 'JAM',
    description: 'JAMAICA',
    parentKey: null
  },
  {
    key: 'JEY',
    description: 'JERSEY',
    parentKey: null
  },
  {
    key: 'JOR',
    description: 'JORDAN',
    parentKey: null
  },
  {
    key: 'JPN',
    description: 'JAPAN',
    parentKey: null
  },
  {
    key: 'KAZ',
    description: 'KAZAKHSTAN',
    parentKey: null
  },
  {
    key: 'KEN',
    description: 'KENYA',
    parentKey: null
  },
  {
    key: 'KGZ',
    description: 'KYRGYZSTAN',
    parentKey: null
  },
  {
    key: 'KHM',
    description: 'CAMBODIA',
    parentKey: null
  },
  {
    key: 'KIR',
    description: 'KIRIBATI',
    parentKey: null
  },
  {
    key: 'KNA',
    description: 'SAINT KITTS AND NEVIS',
    parentKey: null
  },
  {
    key: 'KOR',
    description: 'KOREA, REPUBLIC OF',
    parentKey: null
  },
  {
    key: 'KWT',
    description: 'KUWAIT',
    parentKey: null
  },
  {
    key: 'LAO',
    description: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    parentKey: null
  },
  {
    key: 'LBN',
    description: 'LEBANON',
    parentKey: null
  },
  {
    key: 'LBR',
    description: 'LIBERIA',
    parentKey: null
  },
  {
    key: 'LBY',
    description: 'LIBYA',
    parentKey: null
  },
  {
    key: 'LCA',
    description: 'SAINT LUCIA',
    parentKey: null
  },
  {
    key: 'LIE',
    description: 'LIECHTENSTEIN',
    parentKey: null
  },
  {
    key: 'LKA',
    description: 'SRI LANKA',
    parentKey: null
  },
  {
    key: 'LSO',
    description: 'LESOTHO',
    parentKey: null
  },
  {
    key: 'LTU',
    description: 'LITHUANIA',
    parentKey: null
  },
  {
    key: 'LUX',
    description: 'LUXEMBOURG',
    parentKey: null
  },
  {
    key: 'LVA',
    description: 'LATVIA',
    parentKey: null
  },
  {
    key: 'MAC',
    description: 'MACAO',
    parentKey: null
  },
  {
    key: 'MAF',
    description: 'SAINT MARTIN (FRENCH)',
    parentKey: null
  },
  {
    key: 'MAR',
    description: 'MOROCCO',
    parentKey: null
  },
  {
    key: 'MCO',
    description: 'MONACO',
    parentKey: null
  },
  {
    key: 'MDA',
    description: 'MOLDOVA, REPUBLIC OF',
    parentKey: null
  },
  {
    key: 'MDG',
    description: 'MADAGASCAR',
    parentKey: null
  },
  {
    key: 'MDV',
    description: 'MALDIVES',
    parentKey: null
  },
  {
    key: 'MEX',
    description: 'MEXICO',
    parentKey: null
  },
  {
    key: 'MHL',
    description: 'MARSHALL ISLANDS',
    parentKey: null
  },
  {
    key: 'MKD',
    description: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
    parentKey: null
  },
  {
    key: 'MLI',
    description: 'MALI',
    parentKey: null
  },
  {
    key: 'MLT',
    description: 'MALTA',
    parentKey: null
  },
  {
    key: 'MMR',
    description: 'MYANMAR',
    parentKey: null
  },
  {
    key: 'MNE',
    description: 'MONTENEGRO',
    parentKey: null
  },
  {
    key: 'MNG',
    description: 'MONGOLIA',
    parentKey: null
  },
  {
    key: 'MNP',
    description: 'NORTHERN MARIANA ISLANDS',
    parentKey: null
  },
  {
    key: 'MOZ',
    description: 'MOZAMBIQUE',
    parentKey: null
  },
  {
    key: 'MRT',
    description: 'MAURITANIA',
    parentKey: null
  },
  {
    key: 'MSR',
    description: 'MONTSERRAT',
    parentKey: null
  },
  {
    key: 'MTQ',
    description: 'MARTINIQUE',
    parentKey: null
  },
  {
    key: 'MUS',
    description: 'MAURITIUS',
    parentKey: null
  },
  {
    key: 'MWI',
    description: 'MALAWI',
    parentKey: null
  },
  {
    key: 'MYS',
    description: 'MALAYSIA',
    parentKey: null
  },
  {
    key: 'MYT',
    description: 'MAYOTTE',
    parentKey: null
  },
  {
    key: 'NAM',
    description: 'NAMIBIA',
    parentKey: null
  },
  {
    key: 'NCL',
    description: 'NEW CALEDONIA',
    parentKey: null
  },
  {
    key: 'NER',
    description: 'NIGER',
    parentKey: null
  },
  {
    key: 'NFK',
    description: 'NORFOLK ISLAND',
    parentKey: null
  },
  {
    key: 'NGA',
    description: 'NIGERIA',
    parentKey: null
  },
  {
    key: 'NIC',
    description: 'NICARAGUA',
    parentKey: null
  },
  {
    key: 'NIU',
    description: 'NIUE',
    parentKey: null
  },
  {
    key: 'NLD',
    description: 'NETHERLANDS',
    parentKey: null
  },
  {
    key: 'NOR',
    description: 'NORWAY',
    parentKey: null
  },
  {
    key: 'NPL',
    description: 'NEPAL',
    parentKey: null
  },
  {
    key: 'NRU',
    description: 'NAURU',
    parentKey: null
  },
  {
    key: 'OMN',
    description: 'OMAN',
    parentKey: null
  },
  {
    key: 'PAK',
    description: 'PAKISTAN',
    parentKey: null
  },
  {
    key: 'PAN',
    description: 'PANAMA',
    parentKey: null
  },
  {
    key: 'PCN',
    description: 'PITCAIRN',
    parentKey: null
  },
  {
    key: 'PER',
    description: 'PERU',
    parentKey: null
  },
  {
    key: 'PHL',
    description: 'PHILIPPINES',
    parentKey: null
  },
  {
    key: 'PLW',
    description: 'PALAU',
    parentKey: null
  },
  {
    key: 'PNG',
    description: 'PAPUA NEW GUINEA',
    parentKey: null
  },
  {
    key: 'POL',
    description: 'POLAND',
    parentKey: null
  },
  {
    key: 'PRI',
    description: 'PUERTO RICO',
    parentKey: null
  },
  {
    key: 'PRK',
    description: "KOREA (DEMOCRATIC PEOPLE'S REPUBLIC OF)",
    parentKey: null
  },
  {
    key: 'PRT',
    description: 'PORTUGAL',
    parentKey: null
  },
  {
    key: 'PRY',
    description: 'PARAGUAY',
    parentKey: null
  },
  {
    key: 'PSE',
    description: 'PALESTINE, STATE OF',
    parentKey: null
  },
  {
    key: 'PYF',
    description: 'FRENCH POLYNESIA',
    parentKey: null
  },
  {
    key: 'QAT',
    description: 'QATAR',
    parentKey: null
  },
  {
    key: 'REU',
    description: 'REUNION',
    parentKey: null
  },
  {
    key: 'ROU',
    description: 'ROMANIA',
    parentKey: null
  },
  {
    key: 'RUS',
    description: 'RUSSIAN FEDERATION',
    parentKey: null
  },
  {
    key: 'RWA',
    description: 'RWANDA',
    parentKey: null
  },
  {
    key: 'SAU',
    description: 'SAUDI ARABIA',
    parentKey: null
  },
  {
    key: 'SDN',
    description: 'SUDAN',
    parentKey: null
  },
  {
    key: 'SEN',
    description: 'SENEGAL',
    parentKey: null
  },
  {
    key: 'SGP',
    description: 'SINGAPORE',
    parentKey: null
  },
  {
    key: 'SGS',
    description: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
    parentKey: null
  },
  {
    key: 'SHN',
    description: 'SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA',
    parentKey: null
  },
  {
    key: 'SJM',
    description: 'SVALBARD AND JAN MAYEN',
    parentKey: null
  },
  {
    key: 'SLB',
    description: 'SOLOMON ISLANDS',
    parentKey: null
  },
  {
    key: 'SLE',
    description: 'SIERRA LEONE',
    parentKey: null
  },
  {
    key: 'SLV',
    description: 'EL SALVADOR',
    parentKey: null
  },
  {
    key: 'SMR',
    description: 'SAN MARINO',
    parentKey: null
  },
  {
    key: 'SOM',
    description: 'SOMALIA',
    parentKey: null
  },
  {
    key: 'SPM',
    description: 'SAINT PIERRE AND MIQUELON',
    parentKey: null
  },
  {
    key: 'SRB',
    description: 'SERBIA',
    parentKey: null
  },
  {
    key: 'SSD',
    description: 'South Sudan',
    parentKey: null
  },
  {
    key: 'STP',
    description: 'SAO TOME AND PRINCIPE',
    parentKey: null
  },
  {
    key: 'SUR',
    description: 'SURINAME',
    parentKey: null
  },
  {
    key: 'SVK',
    description: 'SLOVAKIA',
    parentKey: null
  },
  {
    key: 'SVN',
    description: 'SLOVENIA',
    parentKey: null
  },
  {
    key: 'SWE',
    description: 'SWEDEN',
    parentKey: null
  },
  {
    key: 'SWZ',
    description: 'SWAZILAND',
    parentKey: null
  },
  {
    key: 'SXM',
    description: 'Sint Maarten (Dutch)',
    parentKey: null
  },
  {
    key: 'SYC',
    description: 'SEYCHELLES',
    parentKey: null
  },
  {
    key: 'SYR',
    description: 'SYRIAN ARAB REPUBLIC',
    parentKey: null
  },
  {
    key: 'TCA',
    description: 'TURKS AND CAICOS ISLANDS',
    parentKey: null
  },
  {
    key: 'TCD',
    description: 'CHAD',
    parentKey: null
  },
  {
    key: 'TGO',
    description: 'TOGO',
    parentKey: null
  },
  {
    key: 'THA',
    description: 'THAILAND',
    parentKey: null
  },
  {
    key: 'TJK',
    description: 'TAJIKISTAN',
    parentKey: null
  },
  {
    key: 'TKL',
    description: 'TOKELAU',
    parentKey: null
  },
  {
    key: 'TKM',
    description: 'TURKMENISTAN',
    parentKey: null
  },
  {
    key: 'TLS',
    description: 'TIMOR-LESTE',
    parentKey: null
  },
  {
    key: 'TON',
    description: 'TONGA',
    parentKey: null
  },
  {
    key: 'TTO',
    description: 'TRINIDAD AND TOBAGO',
    parentKey: null
  },
  {
    key: 'TUN',
    description: 'TUNISIA',
    parentKey: null
  },
  {
    key: 'TUR',
    description: 'TURKEY',
    parentKey: null
  },
  {
    key: 'TUV',
    description: 'TUVALU',
    parentKey: null
  },
  {
    key: 'TWN',
    description: 'TAIWAN, PROVINCE OF CHINA',
    parentKey: null
  },
  {
    key: 'TZA',
    description: 'TANZANIA, UNITED REPUBLIC OF',
    parentKey: null
  },
  {
    key: 'UGA',
    description: 'UGANDA',
    parentKey: null
  },
  {
    key: 'UKR',
    description: 'UKRAINE',
    parentKey: null
  },
  {
    key: 'UMI',
    description: 'UNITED STATES MINOR OUTLYING ISLANDS',
    parentKey: null
  },
  {
    key: 'URY',
    description: 'URUGUAY',
    parentKey: null
  },
  {
    key: 'UZB',
    description: 'UZBEKISTAN',
    parentKey: null
  },
  {
    key: 'VAT',
    description: 'Vatican',
    parentKey: null
  },
  {
    key: 'VCT',
    description: 'SAINT VINCENT AND THE GRENADINES',
    parentKey: null
  },
  {
    key: 'VEN',
    description: 'VENEZUELA (BOLIVARIAN REPUBLIC OF)',
    parentKey: null
  },
  {
    key: 'VGB',
    description: 'VIRGIN ISLANDS (BRITISH)',
    parentKey: null
  },
  {
    key: 'VIR',
    description: 'VIRGIN ISLANDS (U.S.)',
    parentKey: null
  },
  {
    key: 'VNM',
    description: 'Vietnam',
    parentKey: null
  },
  {
    key: 'VUT',
    description: 'VANUATU',
    parentKey: null
  },
  {
    key: 'WLF',
    description: 'WALLIS AND FUTUNA',
    parentKey: null
  },
  {
    key: 'WSM',
    description: 'SAMOA',
    parentKey: null
  },
  {
    key: 'YEM',
    description: 'YEMEN',
    parentKey: null
  },
  {
    key: 'ZAF',
    description: 'SOUTH AFRICA',
    parentKey: null
  },
  {
    key: 'ZMB',
    description: 'ZAMBIA',
    parentKey: null
  },
  {
    key: 'ZWE',
    description: 'ZIMBABWE',
    parentKey: null
  }
];

const severityRatings = [
  {
    label: 'Low',
    value: 'LOW'
  },
  {
    label: 'Medium',
    value: 'MEDIUM'
  },
  {
    label: 'High',
    value: 'HIGH'
  },
  {
    label: 'Severe',
    value: 'SEVERE'
  },
  {
    label: 'Emergency',
    value: 'EMERGENCY'
  }
];

const sectors = [
  { label: 'Chemical', value: 'CHEMICAL', subsectors: [] },
  {
    label: 'Commercial',
    value: 'COMMERCIAL',
    subsectors: [
      { label: 'Entertainment & Media', value: 'ENTERTAINMENT_AND_MEDIA' },
      { label: 'Gaming', value: 'GAMING' },
      { label: 'Lodging', value: 'LODGING' },
      { label: 'Outdoor Events', value: 'OUTDOOR_EVENTS' },
      { label: 'Public Assembly', value: 'PUBLIC_ASSEMBLY' },
      { label: 'Real Estate', value: 'REAL_ESTATE' },
      { label: 'Retail', value: 'RETAIL' },
      { label: 'Sports Leagues', value: 'SPORTS_LEAGUES' }
    ]
  },
  { label: 'Communication', value: 'COMMUNICATION', subsectors: [] },
  {
    label: 'Critical Manufacturing',
    value: 'CRITICAL_MANUFACTURING',
    subsectors: []
  },
  { label: 'Dams', value: 'DAMS', subsectors: [] },
  { label: 'Defense', value: 'DEFENSE', subsectors: [] },
  { label: 'Emergency', value: 'EMERGENCY', subsectors: [] },
  { label: 'Energy', value: 'ENERGY', subsectors: [] },
  { label: 'Financial', value: 'FINANCIAL', subsectors: [] },
  { label: 'Food & Agriculture', value: 'FOOD_AGRICULTURE', subsectors: [] },
  {
    label: 'Government',
    value: 'GOVERNMENT',
    subsectors: [
      { label: 'Elections', value: 'ELECTIONS' },
      { label: 'Education', value: 'EDUCATION' },
      { label: 'Monuments', value: 'MONUMENTS' }
    ]
  },
  { label: 'Healthcare', value: 'HEALTHCARE', subsectors: [] },
  {
    label: 'Information Technology',
    value: 'INFORMATION_TECHNOLOGY',
    subsectors: []
  },
  {
    label: 'Nuclear Reactors / Materials Waste',
    value: 'NUCLEAR_REACTORS_MATERIALS_WASTE',
    subsectors: []
  },
  {
    label: 'Transportation',
    value: 'TRANSPORTATION',
    subsectors: [
      { label: 'Aviation', value: 'AVIATION' },
      { label: 'Highway', value: 'HIGHWAY' },
      { label: 'Maritime', value: 'MARITIME' },
      { label: 'Mass Transit', value: 'MASS_TRANSIT' },
      { label: 'Pipeline', value: 'PIPELINE' },
      { label: 'Freight / Rail', value: 'FREIGHT_RAIL' },
      { label: 'Postal And Shipping', value: 'POSTAL_AND_SHIPPING' }
    ]
  },
  {
    label: 'Water And Wastewater',
    value: 'WATER_AND_WASTEWATER',
    subsectors: []
  }
];

const observablesTypes = [
  { label: 'N/A', value: '' },
  { label: 'CVE', value: 'CVE' },
  { label: 'DOMAIN', value: 'DOMAIN' },
  { label: 'EMAIL', value: 'EMAIL' },
  { label: 'IPV4', value: 'IPV4' },
  { label: 'IPV6', value: 'IPV6' },
  { label: 'MAC', value: 'MAC' },
  { label: 'MD5', value: 'MD5' },
  { label: 'OTHER', value: 'OTHER' },
  { label: 'SHA1', value: 'SHA1' },
  { label: 'SHA256', value: 'SHA256' },
  { label: 'URL', value: 'URL' }
];

const threatVectors = [
  {
    name: 'Reconnaissance',
    subVectors: [
      { name: 'Information Gathering' },
      { name: 'Network Scan' },
      { name: 'System Scan' }
    ]
  },
  {
    name: 'Authorized User',
    subVectors: [{ name: 'Purposeful' }, { name: 'Accidental' }]
  },
  {
    name: 'Social Engineering',
    subVectors: [{ name: 'Email' }, { name: 'Website' }, { name: 'Other' }]
  },
  {
    name: 'Configuration Management',
    subVectors: [{ name: 'Network' }, { name: 'OS' }, { name: 'Application' }]
  },
  {
    name: 'Software Flaw',
    subVectors: [
      { name: 'Exploited New Vulnerability' },
      { name: 'Exploited Known Vulnerability' }
    ]
  },
  {
    name: 'Unknown'
  }
];

export { severityRatings, sectors, observablesTypes, relTos, threatVectors };
